
import * as aroraProviderRuntime$m8cwMqi4qfgiTdUUv2TJf_45ehzta8Pjqvg_45hIugWC3Cw from 'D:/deploy/UploadHidden/20250320-P903.298/arora-front/satellite/build-rest-cd27dfad-17fc-4438-b4f9-aa5f917b0cd9/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$m8cwMqi4qfgiTdUUv2TJf_45ehzta8Pjqvg_45hIugWC3Cw, defaults: {} }
}
        